import styled from "@emotion/styled";
import {
  Drawer,
  IconButton as MuiIconButton, Tooltip
} from "@mui/material";
import React, { useState } from "react";
import { HelpCircle } from "react-feather";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function Support() {
  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>

      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
          fullScreen: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "30vw", // Faz o Drawer ocupar a largura inteira da tela
            height: "100vh", // Opcional, para ocupar toda a altura
          },
        }}>
        <Demos />
      </Drawer>

      <Tooltip title="Base de Conhecimento">
        <IconButton
          aria-owns={Boolean(null) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleDrawer(true)}
          color="inherit"
          size="large">

          <HelpCircle />

        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

function Demos() {
  return (
    <iframe src="https://ajuda.pontoicarus.com.br/post/2003-2/" style={{ width: '100%', height: '100%' }} />
  );
}

export default Support;
