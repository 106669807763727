//import axios from "../utils/axios";
import Axios from "axios";
import Alert from "../components/alert/Alert";

function relatorioAdicionalNoturno(
  dataInicio,
  dataFim,
  tipo,
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  page
) {
  return new Promise((resolve, reject) => {
    const vo = {
      dataInicio,
      dataFim,
      tipo,
      mutuarios,
      centrosCusto,
      lotacoes,
      regimesContratacao,
      cargos,
    };

    Axios.post(`/ponto/relatorioAdicionalNoturno/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioAdicionalNoturnoAgrupado(
  dataInicio,
  dataFim,
  tipo,
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  page
) {
  return new Promise((resolve, reject) => {
    const vo = {
      dataInicio,
      dataFim,
      tipo,
      mutuarios,
      centrosCusto,
      lotacoes,
      regimesContratacao,
      cargos,
    };

    Axios.post(
      `/ponto/relatorioAdicionalNoturnoAgrupado/${page}`,
      JSON.stringify(vo)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarPontosInconsistentes(centroCusto, page = 0) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }

    Axios.post(`/ponto/buscarPontosInconsistentes/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscaDadosDashboardJornada(centroCusto) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }

    Axios.post(`/ponto/buscaDadosDashboardJornada`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function reprocessarPontos(mutuario, dataBatida) {
  return new Promise((resolve, reject) => {
    const vo = {
      mutuario: { idMutuario: mutuario.idMutuario },
      dataBatida,
    };

    Axios.post(`/ponto/reprocessarPontos/`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioReguladoPorLei(
  centroCusto,
  dataInicio,
  dataFim,
  tipo,
  somenteAtivos,
  lotacao,
  mutuarios
) {
  return new Promise((resolve, reject) => {
    const vo = {
      centroCusto: centroCusto,
      dataInicio: dataInicio,
      dataFim: dataFim,
      lotacao: lotacao,
      tipo: tipo,
      somenteAtivos: somenteAtivos,
      mutuarios: mutuarios,
    };

    Axios.post(`/ponto/relatorioReguladoPorLei`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function relatorioReguladoPorLeiAssinado(
  centroCusto,
  dataInicio,
  dataFim,
  tipo,
  somenteAtivos,
  lotacao,
  mutuarios
) {
  return new Promise((resolve, reject) => {
    const vo = {
      centroCusto: centroCusto,
      dataInicio: dataInicio,
      dataFim: dataFim,
      lotacao: lotacao,
      tipo: tipo,
      somenteAtivos: somenteAtivos,
      mutuarios: mutuarios,
    };

    Axios.post(`/ponto/relatorioReguladoPorLeiAssinado`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioFaltas({
  centroCusto,
  status,
  turnos,
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  pagina = 0,
  dataInicio,
  dataFim,
  fechado
}) {
  return new Promise((resolve, reject) => {
    let vo = {
      dataInicio,
      dataFim,
      turnos,
      centroCusto,
      mutuarios,
      centrosCusto,
      lotacoes,
      status,
      regimesContratacao,
      cargos,
      fechado
    };

    Axios.post(`/ponto/relatorioFaltas/${pagina}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



function relatorioBuscarPontosDivergenteRelacaoTurno({
  dataInicio,
  dataFim,
  centroCusto,
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos
}) {
  return new Promise((resolve, reject) => {
    let vo = {
      dataInicio,
      dataFim,
      centroCusto,
      mutuarios,
      centrosCusto,
      lotacoes,
      regimesContratacao,
      cargos
    };

    Axios.post(`/ponto/buscarPontosDivergenteRelacaoTurno`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



function relatorioAtrasos({
  centroCusto,
  mutuarios,
  status,
  centrosCusto,
  lotacoes,
  turnos,
  regimesContratacao,
  cargos,
  pagina = 0,
  dataInicio,
  dataFim,
}) {
  return new Promise((resolve, reject) => {
    let vo = {
      dataInicio,
      dataFim,
      status,
      centroCusto,
      turnos,
      mutuarios,
      centrosCusto,
      lotacoes,
      regimesContratacao,
      cargos,
    };

    Axios.post(`/ponto/relatorioAtrasos/${pagina}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarSolicitacoesPendentesAprovacao(centroCusto, page = 0) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }
    Axios.post(
      `/ponto/buscarSolicitacoesPendentesAprovacao/${page}`,
      JSON.stringify(vo)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarDadosPontoDashboard(centroCusto, dataInicio, dataFim) {
  return new Promise((resolve, reject) => {
    let vo = {
      dataInicio, dataFim, centroCusto
    };

    Axios.post(`/ponto/buscaDadosDashboard`, vo)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function detalhaResumoHorasPorDia(data, centroCusto,
  filtrarNormal,
  filtrarExtra,
  filtrarFalta,
  filtrarAdNoturno,
  page) {
  return new Promise((resolve, reject) => {

    let vo = {
      centroCusto,
      filtrarNormal,
      filtrarExtra,
      filtrarFalta,
      filtrarAdNoturno,
    };

    Axios.post(
      `/ponto/detalhamentoResumoHorasPorDia/${page}/${data.toISOString().split('T')[0]}`,
      JSON.stringify(vo)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function detalharDadosDashboard(centroCusto) {
  return new Promise((resolve, reject) => {
    let vo = undefined;

    if (centroCusto) {
      vo = centroCusto;
    }

    Axios.post(`/ponto/detalharDadosDashboard`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function consultarRegistrosPonto(
  idColaborador,
  dataInicio,
  dataFim,
  esconderDiasDescanso
) {
  return new Promise((resolve, reject) => {
    const vo = {
      idColaborador,
      dataInicio,
      dataFim,
      esconderDiasDescanso,
    };

    Axios.post(`/ponto/consultarRegistrosPonto`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarTodosPontosInclusiveIgnorados(idColaborador, data) {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/ponto/buscarTodosPontosInclusiveIgnorados/${idColaborador}/${data}`
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function adicionarNotaPonto(mutuario, dataBatida, nota) {
  return new Promise((resolve, reject) => {
    const vo = {
      dataBatida,
      mutuario,
      nota,
    };

    Axios.post(`/ponto/adicionarNotaPonto`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function bater(idMutuario, latitude, longitude, precisao) {
  return new Promise((resolve, reject) => {
    const vo = {
      idMutuario,
      latitude,
      longitude,
      precisao,
      meioBatida: "NAVEGADOR",
    };

    Axios.post(`/ponto/bater`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function downloadComprovante(idPontoHorarioBatida) {
  return new Promise((resolve, reject) => {
    Axios.get(`/ponto/downloadComprovante/${idPontoHorarioBatida}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function reprocessarTodosAPartir({ dataReprocessamento }) {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/ponto/reprocessarTodosAPartir/`,
      JSON.stringify(dataReprocessamento)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function buscarHorariosPreenchimentoAutomatico(data, idColaborador) {
  return new Promise((resolve, reject) => {
    Axios.get(`/ponto/horariosPreenchimentoAutomatico/${data}/${idColaborador}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function findById(id) {
  return new Promise((resolve, reject) => {
    Axios.get(`/ponto/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioEspelhoDeJornada({
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  dataInicio,
  dataFim,
  filtros,
  page,
  esconderDiasDescanso
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      mutuarios: mutuarios,
      centrosCusto: centrosCusto,
      lotacoes: lotacoes,
      regimesContratacao: regimesContratacao,
      cargos: cargos,
      dataInicio: dataInicio,
      dataFim: dataFim,
      filtroRelatorioEspelhoPonto: filtros,
      esconderDiasDescanso
    };

    Axios.post(`/ponto/relatorioEspelhoDeJornada/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioTotalizadorDeJornada({
  mutuarios,
  filtros,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  dataInicio,
  dataFim,
  page,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      mutuarios: mutuarios,
      filtroRelatorioEspelhoPonto: filtros,
      centrosCusto: centrosCusto,
      lotacoes: lotacoes,
      regimesContratacao: regimesContratacao,
      cargos: cargos,
      dataInicio: dataInicio,
      dataFim: dataFim,
    };

    Axios.post(
      `/ponto/relatorioTotalizadorDeJornada/${page}`,
      JSON.stringify(vo)
    )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioInterIntraJornarda({
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  dataInicio,
  dataFim,
  tempoMinimo,
  tipoIntervalo,
  page,
}) {
  return new Promise((resolve, reject) => {
    const vo = {
      mutuarios: mutuarios,
      centrosCusto: centrosCusto,
      lotacoes: lotacoes,
      regimesContratacao: regimesContratacao,
      cargos: cargos,
      dataInicio: dataInicio,
      dataFim: dataFim,
      tempoMinimo: tempoMinimo,
      tipoIntervalo,
    };

    Axios.post(`/ponto/relatorioInterIntraJornarda/${page}`, JSON.stringify(vo))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function relatorioEspelhoDeJornadaCompleto({
  dataInicio,
  dataFim,
  mutuarios,
  centrosCusto,
  lotacoes,
  regimesContratacao,
  cargos,
  filtros,
  esconderDiasDescanso,
}) {
  const vo = {
    dataInicio,
    dataFim,
    mutuarios,
    centrosCusto,
    lotacoes,
    regimesContratacao,
    cargos,
    filtroRelatorioEspelhoPonto: filtros,
    esconderDiasDescanso
  };

  return new Promise((resolve, reject) => {
    Axios.post(`/ponto/relatorioEspelhoDeJornadaCompleto`, JSON.stringify(vo), {
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        error.response.data
          .text()
          .then((message) =>
            Alert.error(
              JSON.parse(message).message,
              JSON.parse(message).message
            )
          );
        reject(error);
      });
  });
}

const PontoService = {
  buscarPontosInconsistentes,
  buscarHorariosPreenchimentoAutomatico,
  relatorioFaltas,
  findById,
  relatorioTotalizadorDeJornada,
  relatorioEspelhoDeJornadaCompleto,
  buscarDadosPontoDashboard,
  relatorioReguladoPorLei,
  detalharDadosDashboard,
  buscarSolicitacoesPendentesAprovacao,
  consultarRegistrosPonto,
  bater,
  relatorioInterIntraJornarda,
  adicionarNotaPonto,
  downloadComprovante,
  reprocessarPontos,
  reprocessarTodosAPartir,
  detalhaResumoHorasPorDia,
  buscarTodosPontosInclusiveIgnorados,
  relatorioReguladoPorLeiAssinado,
  relatorioEspelhoDeJornada,
  relatorioAdicionalNoturnoAgrupado,
  relatorioAdicionalNoturno,
  relatorioBuscarPontosDivergenteRelacaoTurno,
  buscaDadosDashboardJornada,
  relatorioAtrasos,
};

export default PontoService;
